<template>
	<SubPageListLayout :loading="builderStore.loading">
		<template #sub_bar>
			<div class="min-h-0 flex-1 overflow-y-auto">
				<RouterLink
					v-for="item in subNavigation"
					:key="item.name"
					:class="[route.name === item.to?.name ? 'bg-primary-50 bg-opacity-50 shadow-inner' : 'hover:bg-primary-50', 'first:pt-4 flex p-3 border-b border-blue-gray-200 cursor-pointer']"
					:aria-current="route.name === item.to?.name ? 'page' : undefined"
					:to="item.to"
				>
					<FontAwesomeIcon :icon="item.icon" aria-hidden="true" class="mt-0.5 h-6 w-6 flex-shrink-0 text-primary pointer-events-none" />
					<div class="ml-3 text-sm pointer-events-none">
						<p class="font-bold text-black">{{ item.name }}</p>
						<p class="mt-1 text-black">{{ item.description }}</p>
					</div>
				</RouterLink>
			</div>
		</template>
		<template #sub_content>
			<RouterView />
			<div v-if="route.name === 'builder.settings'" class="flex flex-row w-full h-full justify-center">
				<div class="transition-all duration-500 transform w-full h-full">
					<div class="rounded-lg px-7 pb-6 pt-4 sm:p-10 shadow-box-circle mx-auto transition-all duration-500 transform w-full flex flex-row justify-center items-center h-full">
						<div class="flex flex-col items-center">
							<h1 class="text-3xl font-bold">Welcome to activity settings</h1>
							<p class="mt-4">Here you can alter the way this activity is viewed.</p>
							<FontAwesomeIcon :icon="faWandMagicSparkles" aria-hidden="true" class="mt-8 h-24 w-24 flex-shrink-0 text-primary pointer-events-none text-4xl" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</SubPageListLayout>
</template>

<script lang="ts" setup>
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faBell, faGears, faKey, faMessages } from '@fortawesome/pro-solid-svg-icons';
	import { LocationAsRelativeRaw, useRoute } from 'vue-router';
	import { faWandMagicSparkles, IconDefinition } from '@fortawesome/pro-light-svg-icons';
	import SubPageListLayout from '@layouts/SubPageListLayout.vue';
	import { useBuilderStore } from '@modules/builder/store';

	const route = useRoute();
	const builderStore = useBuilderStore();

	export interface SubNavigationTab {
		name: string;
		description: string;
		to?: LocationAsRelativeRaw;
		icon?: IconDefinition;
	}

	const subNavigation: SubNavigationTab[] = [
		{
			name: 'General',
			description: 'Alter general settings for this template, such as labels, appearance, and much more.',
			to: { name: 'builder.settings.general', params: route.params },
			icon: faGears,
		},
		{
			name: 'Interactions',
			description: 'Control how interactions work for this template. Here you can control things such as appearance, what tags can be used, and much more.',
			to: { name: 'builder.settings.interaction', params: route.params },
			icon: faMessages,
		},
		{
			name: 'Notifications',
			description: 'Control how notifications and alerts work for this template.',
			to: { name: 'builder.settings.notifications', params: route.params },
			icon: faBell,
		},
		{
			name: 'Security',
			description: 'Here you are able to control what permissions a user requires in order to perform certain actions for this template.',
			to: { name: 'builder.settings.security', params: route.params },
			icon: faKey,
		},
	];
</script>

<style scoped></style>
